import { Component } from 'react';
import { inject } from 'mobx-react';
import { WINDOW_EXISTS } from '../../../helpers/viewport'

@inject('routerStore', 'readProgressStore')
class ScrollToTop extends Component {

  constructor(props) {
    super(props);
    this.history = this.props.routerStore.history;
    this.shouldScroll = !this.props.onHistoryPush || this.history.action === 'PUSH';
    this.progress = props.hasProgress ? props.readProgressStore.readProgress[props.slug] : 0;
  }

  async componentDidMount() {
    if(this.progress && WINDOW_EXISTS) {
      const footerHeight = document.getElementsByClassName('mink-footer')[0]?.scrollHeight ?
        document.getElementsByClassName('mink-footer')[0].scrollHeight : 0;
      const targetPos = (document.documentElement.scrollHeight - window.innerHeight - footerHeight) * this.progress / 100;
      window.scrollTo({ top: targetPos, left: 0, behavior: 'instant' });
    } else if(this.shouldScroll && WINDOW_EXISTS) {
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }
  }

  render() {
    return null;
  }
}
export default ScrollToTop;