import * as React from "react";

function IconTriangle(props) {
  return (
    <svg
      {...props}
      fill="currentColor"
      width="100%"
      height="100%"
      viewBox="0 0 25 24">
      <path d="M0,0L24.052,12L0,24L0,0Z"/>
    </svg>
  );
}

export default IconTriangle;