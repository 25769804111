import React from 'react';
import MainMenu from '../../components/nav/MainMenu/MainMenu';
import SubMenu from '../../components/nav/SubMenu/SubMenu';
import { Link } from 'react-router-dom';
import useEventListener from '@use-it/event-listener';
import { Collapse } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import MobileMenu from '../../components/nav/MobileMenu/MobileMenu';
import Logo from '../../components/elements/svg/Logo';

const Nav = inject('navigationStore', 'routerStore', 'windowStore')(
  observer((props) => {
    const menuItems = props.navigationStore.primaryNavigationItems || [];
    const currentPath = props.routerStore.location.pathname;
    const isCurrentMenuItem = props.navigationStore.isCurrentMenuItem;
    const handleClick = props.navigationStore.handleClick;
    const subMenuIsOpen = props.navigationStore.subMenuIsOpen;
    const subMenuId = props.navigationStore.subMenuId;

    const openSubMenu = (id) => {
      props.navigationStore.openSubMenu(id);
    }

    const elementNavDesktop = React.createRef();

    const handleDocumentClick = (e) => {
      // close menu on outside clicks
      if ( elementNavDesktop?.current && !elementNavDesktop.current.contains(e.target) ) {
        props.navigationStore.closeNav();
      }
    };

    useEventListener('click', handleDocumentClick, document, { capture: true });

    return (
      <>
        { props.windowStore.isGreaterEqualThan('xxl') && (
          <nav className="mink-main-nav" ref={elementNavDesktop}>
            <div className={'mink-main-nav__container'}>
              <div className="mink-main-nav__menu">
                <MainMenu
                  items={menuItems}
                  depthLevel={0}
                  isCurrentMenuItem={isCurrentMenuItem}
                  handleClick={handleClick}
                  currentPath={currentPath}
                  openSubMenu={openSubMenu}
                  currentSubMenu={subMenuId}
                  subMenuIsOpen={subMenuIsOpen}
                />
              </div>
            </div>

            <Collapse isOpen={subMenuIsOpen} className={'mink-main-nav__submenu-container'}>
              {
                menuItems.map((item, i) => {
                  if (Array.isArray(item.children) && item.children.length > 0) {
                    return (
                      <div className={'mink-main-nav__submenu ' +
                        item.classes.join(' ') +
                        (subMenuId === item.menu_item_id ? '' : ' d-none')}
                        key={item.menu_item_id}>
                        <SubMenu
                          items={item.children}
                          depthLevel={1}
                          isCurrentMenuItem={isCurrentMenuItem}
                          handleClick={handleClick}
                          currentPath={currentPath}/>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })
              }
            </Collapse>
          </nav>
        )}

        <div className="mink-main-logo"><Link to={'/'}><Logo className="logo-svg" /></Link></div>

        { props.windowStore.isSmallerThan('xxl') && (
          <MobileMenu items={menuItems}></MobileMenu>
        )}
      </>
    );
  })
);

export default Nav;
