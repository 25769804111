import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { WINDOW_EXISTS } from '../../../helpers/viewport';

const ReadProgressWatcher = inject('readProgressStore')(
  observer((props) => {

    useEffect(() => {
      const handleScroll = (e) => {
        let scrollTop = e.detail.scrollPositionY;
        const windowHeight = WINDOW_EXISTS ? window.innerHeight : 0;
        const progress = Math.max(0, Math.min(100,
          scrollTop * 100 / (document.body.clientHeight - 1 - windowHeight)));

        props.readProgressStore.setReadProgress(props.slug, progress);
      };

      if (WINDOW_EXISTS) {
        window.addEventListener('window-scroll', handleScroll, { passive: true });
      }
      // clean up
      return () => {
        if (WINDOW_EXISTS) {
          window.removeEventListener('window-scroll', handleScroll, { passive: true });
        }
      };
    }, [props.readProgressStore, props.slug]);

    return (
      <></>
    );
  })
);
export default ReadProgressWatcher
