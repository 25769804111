import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';

const MobileMenuSearch = inject('navigationStore', 'routerStore', 'windowStore')(
  observer((props) => {
    const [search, setSearch] = useState('');

    const elementInput = React.createRef();

    const onKeyDown = (event) => {
      if (event.key === 'Enter' && search.trim().length > 1) {
        const item = {
          url_components: {
            path: '/suche/?suche=' + encodeURI(search.trim()),
          }
        }

        props.navigationStore.handleClick(event, item);
        setSearch('');
        elementInput.current.blur();
        onMouseOut();
      }
    }

    const onFocus = (event) => {
      if (props.onFocus) props.onFocus(event);
    }
    const onBlur = (event) => {
      if (props.onBlur) props.onBlur(event);
    }
    const onMouseOver = (event) => {
      if (props.onMouseOver) props.onMouseOver(event);
    }
    const onMouseOut = (event) => {
      if (props.onMouseOut) props.onMouseOut(event);
    }

    return (
      <input
        ref={elementInput}
        className="search-input"
        type="search"
        id="search"
        name="search"
        aria-label="Suchbegriff"
        autoComplete="off"
        minLength="2"
        maxLength="30"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyDown={onKeyDown}
        onFocus={onFocus} onBlur={onBlur}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      />
    );
  })
);

export default MobileMenuSearch;
