import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import useEventListener from '@use-it/event-listener';
import IconTop from '../../elements/svg/IconTop';
import IconTopHover from '../../elements/svg/IconTopHover';

const ScrollTopButton = inject('scrollStore')(
  observer((props) => {
    const [visible, setVisible] = useState(false);

    const onWindowScroll = () => {
      if (window?.scrollY > 400) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };
    useEventListener('scroll', onWindowScroll, window, { passive: true });

    const topClick = () => {
      if (typeof window !== 'undefined') {
        props.scrollStore.animateScrollTo({ desiredOffset: 0 });
      }
    };

    return (
      <div className="mink-scroll-top-button__container">
        <div className="container position-relative">
          <div
            title="Zum Anfang"
            className={'mink-scroll-top-button' + (visible ? ' is-visible' : '')}
            onClick={topClick}
          >
            <IconTop className={'icon-normal'} />
            <IconTopHover className={'icon-hover'} />
          </div>
        </div>
      </div>
    );
  })
);

export default ScrollTopButton;
