import { action, observable, makeObservable } from 'mobx';
import { matchPath } from 'react-router-dom';
import { getCurrentRoute } from '../routes/routes';


export default class ArchivesStore {

  /**
   * @module wpapi
   * wpapi.WPAPI
   */
  api;
  data = [];
  isFetched = new Map();
  isFetching = new Map();
  slug = '';
  currentRoute = {}
  perPage = 6;

  constructor( api, routerStore ) {
    makeObservable(this, {
      isFetched: observable,
      isFetching: observable,
      currentRoute: observable,
      fetch: action,
      fetchSuccess: action.bound,
      fetchTermArchiveIfNeeded: action,
      setCurrentRoute: action
    });

    this.api = api;
    this.routerStore = routerStore;

    // this.getCurrentMatch();
  }

  getCurrentMatch() {
    this.match = matchPath(this.routerStore.location.pathname, {path: '/:id([\\w-]*)'});
    this.slug = this.match && '' !== this.match.params.id ? this.match.params.id : 'homepage';
  }

  fetch(perPage) {
    const route = this.currentRoute;
    const {path, apiParams} = route;
    this.isFetching.set(path, true);

    const params = {
      post_type : apiParams.postType,
      type: apiParams.type,
    };
    if (perPage) {
      params.per_page = perPage;
    }

    if (this.data[path] && this.data[path].posts ) {
      params.offset = this.data[path].posts.length;
    }

    if ('taxonomy' === apiParams.type) {
      params[apiParams.restBase] = apiParams.termId
    }

    this.api.posts = this.api.registerRoute(
      'mink/v1',
      '/posts/',
      { params: ['param']
    });
    this.api.posts()
      .param(params)
      .then(this.fetchSuccess)
      .catch(error => console.error(error));
  }

  fetchSuccess(response) {
    const route = this.currentRoute;
    const {path} = route;
    if ( response ) {
      if(response._paging && response._paging.total) {
        response.total = parseInt(response._paging.total, 10);
      }
      if (this.data[route.path]) {
        this.data[route.path].posts = this.data[route.path].posts.concat(response.posts);
      } else {
        this.data[route.path] = response;
      }
    } else {
      this.data[route.path] = false;
    }

    this.isFetched.set(route.path, true);
    this.isFetching.set(path, false);
  }

  fetchIfNeeded() {
    this.setCurrentRoute();
    const route = this.currentRoute;
    if ( !this.isFetching.get(route.path) && !this.isFetched.get(route.path)) {
      this.fetch(this.perPage);
    }
  };

  /**
   * fetch next items from current route
   */
  fetchNext() {
    this.setCurrentRoute();
    const route = this.currentRoute;
    if (
      this.isFetched.get(route.path) &&
      !this.isFetching.get(route.path) &&
      this.data[route.path].total > this.data[route.path].posts.length
    ) {
      this.fetch(this.perPage);
    }

  }

  /**
   * fetch by path, for lazy loading archive data, independently from router location
   * @param {Route Object} route
   */
  fetchTermArchiveIfNeeded( route ) {
    if ( route.path && !this.isFetching.get(route.path) && !this.isFetched.get(route.path)) {
      this.currentRoute = route;
      this.fetch(false);
    }
  };

  setCurrentRoute() {
    const route = getCurrentRoute();
    if (
      route &&
      route.apiParams &&
      -1 !== ['post_type_archive', 'taxonomy'].indexOf(route.apiParams.type) &&
      route.path !== this.currentRoute.path
    ) {
      this.currentRoute = route;
    }
  }
}

