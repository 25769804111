import { action, observable, makeObservable } from 'mobx';

const PROGRESS_KEY = 'mink.readProgress';

export default class ReadProgressStore {

  readProgress = {};

  constructor() {
    makeObservable(this, {
      readProgress: observable,
      setReadProgress: action,
    });

    if (localStorage) {
      const jsonData = localStorage.getItem(PROGRESS_KEY);
      if(jsonData) {
        try {
          this.readProgress = JSON.parse(jsonData);
        } catch (error) {
          this.readProgress = {};
        }
      }
    }
  }

  setReadProgress(slug, progress) {
    if (slug && progress && !isNaN(progress)) {
      const currentProgress = this.readProgress[slug];
      if (!currentProgress || isNaN(currentProgress) || progress > currentProgress) {
        this.readProgress[slug] = progress;
        if (localStorage) {
          localStorage.setItem(PROGRESS_KEY, JSON.stringify(this.readProgress));
        }
      }
    }
  }

}