import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import ScrollToTop from '../../components/nav/ScrollToTop/ScrollToTop';
import LoadingAnimation from '../../components/nav/LoadingAnimation/LoadingAnimation';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import ScrollTopButton from '../../components/nav/ScrollTopButton/ScrollTopButton';

const Search = inject(
  'pagesStore',
  'navigationStore',
  'windowStore'
)(
  observer((props) => {

    const store = props.store ? props.store : props.pagesStore;

    const getSearchString = () => {
      const searchParams = new URLSearchParams(props.location.search);
      if (searchParams) {
        const searchString = searchParams.get('suche');
        return encodeURI(searchString);
      }
      return undefined;
    }
    const searchString = getSearchString();

    useEffect(() => {
      if (searchString) {
        store.fetchSearchIfNeeded(searchString);
      }

      document.body.classList.add('page');

      return () => {
        document.body.classList.remove('page');
      };
    });

    const isFetched = store.isFetched.get('search/' + searchString);
    const searchResults = store.data.search[searchString];

    const getSlugFromSearchLink = (link) => {
      if (!link) return '';

      // https://kunstszeneost.de/glossary/robert-rehfeldt/
      // -> /glossar/#robert-rehfeldt
      // https://kunstszeneost.de/thomas-ranft/
      // -> /thomas-ranft/

      if (link.startsWith('https://')) {
        link = link.substring(8);
        const endOfHostname = link.indexOf('/');
        if (endOfHostname > -1) {
          link = link.substring(endOfHostname);
        }
      }
      link = cleanupGlossaryLink(link);
      return link;
    }

    const cleanupGlossaryLink = (link) => {
      link = link.replace('/glossary/' , '/glossar/#');
      if (link.endsWith('/')) {
        link = link.substring(0, link.length - 1);
      }
      return link;
    }

    return (
      <div>
        <Header />
        {isFetched ? (
          <main className="mink-main">
            <div className="container">
              <div className="mink-page">
                <div className="mink-content">
                  <ScrollToTop onHistoryPush={true} />
                  {/* {renderHead()} */}
                  <div className="row">
                    <div className="col-12">
                      <h1 className="mink-search__headline">Suchergebnisse</h1>

                      { Array.isArray(searchResults) && searchResults.length > 0 ? (
                        <div className="mink-search">
                          {
                            searchResults.map((result, i) => {
                              const urlPath = getSlugFromSearchLink(result.url);

                              return (
                                <div key={ result.id } className="mink-search__entry">
                                  <div className="mink-search__title">{ (result.subtype === 'glossary' ? 'Glossar - ' : '') + result.title }</div>
                                  <Link className={'mink-search__link'} to={urlPath}>
                                    <div className="">{ cleanupGlossaryLink(result.url) }</div>
                                  </Link>
                                </div>
                              );
                            })
                          }
                        </div>
                      ) : (
                        <div className="mink-search__no-entries">Keine Einträge gefunden</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        ) : (
          <LoadingAnimation />
        )}
        <ScrollTopButton />
        <Footer />
      </div>
    );
  })
);
export default Search;
