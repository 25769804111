import React, { useState } from 'react';
import { renderText } from '../../../helpers/api';
import IconSearch from '../../elements/svg/IconSearch';
import SearchInput from '../SearchInput/SearchInput';

const MainMenu = (props) => {

  const [searchFocused, setSearchFocused] = useState('');
  const [searchHover, setSearchHover] = useState('');

  const handleClick = item => e => {
    if (Array.isArray(item.children) && item.children.length > 0) {
      e.preventDefault();
      props.openSubMenu(item.menu_item_id);
    } else if (item.url) {
      props.handleClick(e, item);
    }
  };

  const items = [
    ...props.items,
    { title: '',
      url: '',
      menu_item_id: 'search',
      classes: ['search'],
    }
  ];

  const onSearchFocus = (event) => {
    setSearchFocused(true);
    props.openSubMenu(null);
  }
  const onSearchBlur = (event) => {
    setSearchFocused(false);
  }
  const onSearchOver = (event) => {
    setSearchHover(true);
  }
  const onSearchOut = (event) => {
    setSearchHover(false);
  }

  return (
    <ul className={'main-menu list-unstyled'}>
    {
      items.map((item, i) => {

        return (
          <li
            key={item.menu_item_id}
            className={
              'nav_item ' +
              (item.classes.join(' ')) +
              (props.subMenuIsOpen && props.currentSubMenu === item.menu_item_id ? ' is-open' : '')
            }
          >
            { item.menu_item_id === 'search' ? (
              <div className={ 'search-link' + (searchFocused ? ' isFocused' : '')  + (searchHover ? ' isHovered' : '') }>
                <IconSearch className="search-icon" />
                <SearchInput
                  onFocus={onSearchFocus}
                  onBlur={onSearchBlur}
                  onMouseOver={onSearchOver}
                  onMouseOut={onSearchOut}
                />
              </div>
            ) : (
              <a href={item.url} onClick={handleClick(item)} target={item.target}>
                { renderText(item.title) }
              </a>
            )}
          </li>
        );
      })
    }
    </ul>
  );
}

export default MainMenu
