import React, { Component } from 'react';
import SubMenu from '../../components/nav/SubMenu/SubMenu';
import { observer, inject } from 'mobx-react';

@inject('navigationStore', 'routerStore')
@observer
class FooterNav extends Component {

  menuItemsTop = [];
  menuItemsBottom = [];
  menuInterviews = [];
  menuVideos = [];

  constructor(props) {
    super(props);

    this.menuInterviews = [
      { title: 'Interviews', url: '/', menu_item_id: 'Interviews', classes: [], url_components: {}, children: props.navigationStore.footerNavigationInterviewItems || [] }
    ];
    this.menuVideos = [
      { title: 'Videos — 5 FRAGEN', url: '/videos', menu_item_id: 'Videos', classes: [], url_components: {}, children: props.navigationStore.footerNavigationVideoItems || [] }
    ];

    this.menuItemsBottom = props.navigationStore.footerNavigationItems || [];
    this.menuItemsTop = props.navigationStore.secondaryNavigationItems || [];
  }

  render () {
    const currentPath = this.props.routerStore.location.pathname;
    const isCurrentMenuItem = this.props.navigationStore.isCurrentMenuItem;
    const handleClick = this.props.navigationStore.handleClick;

    return (
      <nav className="mink-footer__nav row">
        <div className="mink-footer__nav-menu col-6 col-lg-5">
          <SubMenu items={this.menuInterviews} depthLevel={0} isCurrentMenuItem={isCurrentMenuItem} handleClick={handleClick} currentPath={currentPath}/>
        </div>
        <div className="mink-footer__nav-menu col-6 col-lg-5">
          <SubMenu items={this.menuVideos} depthLevel={0} isCurrentMenuItem={isCurrentMenuItem} handleClick={handleClick} currentPath={currentPath}/>
        </div>
        <div className="mink-footer__nav-menu -misc col-12 col-lg-2">
          <div className="row">
            <div className="col-6 col-lg-12 -top">
              <SubMenu items={this.menuItemsTop} depthLevel={0} isCurrentMenuItem={isCurrentMenuItem} handleClick={handleClick} currentPath={currentPath}/>
            </div>
            <div className="col-6 col-lg-12 -bottom">
              <SubMenu items={this.menuItemsBottom} depthLevel={0} isCurrentMenuItem={isCurrentMenuItem} handleClick={handleClick} currentPath={currentPath}/>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default FooterNav
