import React from 'react';

const VideoButton = (props) => {

  return (
    <div onClick={props.onClick} className={'mink-video-button' + (props.isPressed ? ' is-pressed' : '')}>
      {props.showTitle &&
        <div className="mink-video-button__title-overlay">
          <span className="topline">5 Fragen an</span>
          <span className="title">{ props.title }</span>
        </div>
      }
      <img src={props.isPressed ? props.imgTitle : props.imgVideo} alt={props.title} />
    </div>
  );
}

export default VideoButton
