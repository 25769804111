import './helpers/polyfill';
import 'react-app-polyfill/ie11';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles/main.scss';
import App from './App';
import { unregister } from './registerServiceWorker';
import { Provider } from 'mobx-react';
import { Router, Route } from 'react-router-dom';
import { default as stores, history } from './stores/index';

const container = document.getElementById('app_root');
const root = createRoot(container);
root.render(
  <Provider {...stores} history={history}>
    <Router history={history}>
      <Route path="*" component={App} />
    </Router>
  </Provider>
);
unregister();
