import React from 'react';
import { observer, inject } from 'mobx-react';
import GlossaryNavItem from './GlossaryNavItem';

const GlossaryNav = inject('navigationStore')(
  observer((props) => {

    const generateLetters = () => {
      let letterArray = [];
      for (let index = 'a'.charCodeAt(0); index <= 'z'.charCodeAt(0); index++) {
        const letter = String.fromCharCode(index);
        if (letter !== 'y') {
          letterArray.push(letter);
        }
      }
      return letterArray;
    };

    const letters = generateLetters();

    return (
      <div className="mink-glossary__nav">
        { letters.map( (letter, i) => {
          return (
            <GlossaryNavItem key={ letter } letter={ letter } />
          )
        }) }
      </div>
    );
  })
);

export default GlossaryNav;
