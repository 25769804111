import { action, observable, makeObservable, runInAction } from 'mobx';
import { matchPath } from 'react-router-dom';
import { getRouteByPath } from '../routes/routes';
import { getQueryStringParams, getLocation, getHomeUrl } from '../helpers/api';

export default class PagesStore {
  /**
   * @module wpapi
   * wpapi.WPAPI
   */
  api;
  data = {
    posts: {},
    ids: [],
    meta: {},
    search: {},
  };
  isFetched = new Map();
  isFetching = [];
  restBase = 'mink';

  constructor(api, routerStore) {
    this.api = api;
    this.routerStore = routerStore;
    makeObservable(this, {
      isFetched: observable,
      fetch: action,
      fetchSearchIfNeeded: action,
    });
  }

  /**
   * @todo get rewrite slug from matching route for retrieving custom posttypes, f.e. path: "/videos/:id/"
   * @param {object} params
   */
  fetch(params) {
    // check for object param
    let { path, postType, slug } = params;
    path = !path ? slug : path;
    this.isFetching[path] = true;

    if (slug === 'suche') {
      // this.api.page = this.api.registerRoute('wp/v2', '/search/(?P<id>[\\w-]+)', { params: ['param'] });
    } else {
      this.api.page = this.api.registerRoute(this.restBase + '/v1', '/pages/(?P<id>[\\w-]+)', { params: ['param'] });
    }
    const fetchParams = {};
    if (postType) {
      fetchParams.post_type = postType;
    }
    const location = getLocation();
    const queryParams = getQueryStringParams(location.search);
    if (queryParams.subdomain) {
      fetchParams.subdomain = queryParams.subdomain;
    }
    this.api
      .page()
      .param(fetchParams)
      .id(slug)
      .then((post) => {
        if (post && (post.hasOwnProperty('ID') || post.hasOwnProperty('location'))) {
          this.data.posts[path] = post;
        } else {
          this.data.posts[path] = false;
        }
        this.isFetched.set(path, true);
        this.isFetching[path] = false;
      })
      .catch((error) => {
        console.error(error);
        this.data.posts[path] = false;
        this.isFetched.set(path, false);
        this.isFetching[path] = false;
      });
  }

  fetchIfNeeded(slug) {
    slug = '' !== slug ? slug : 'homepage';
    if (!this.isFetching[slug] && !this.isFetched.get(slug)) {
      this.fetch({ slug: slug });
    }
  }

  fetchPathIfNeeded(path) {
    if (!this.isFetching[path] && !this.isFetched.get(path)) {
      const route = getRouteByPath(path);
      const match = route && matchPath(path, route);
      if (match) {
        const params = {
          path: path,
          slug: match.params.id,
          postType: route.apiParams.postType,
        };
        this.fetch(params);
      }
    }
  }

  async fetchSearchIfNeeded(searchString) {
    const homeUrl = getHomeUrl();

    if (searchString) {
      // searchString = encodeURI(searchString);

      try {
        const response = await fetch(homeUrl + 'wp-json/wp/v2/search/?search=' + searchString + '&per_page=100');
        if (!response.ok) {
          throw new Error(`Response status: ${response.status}`);
        }

        const json = await response.json();
        if (json) {
          runInAction(() => {
            this.data.search[searchString] = json;
            this.isFetched.set('search/' + searchString, true);
          });
        }
      } catch (error) {
        console.error(error.message);
      }
    }
  }

}
