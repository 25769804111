import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import BlockButton from './BlockButton';

/**
 * youtube iframe component with blocker for iframe
 */
@inject('blockDataStore')
@observer
export default class YoutubeIFrameInterview extends Component {

  render () {
    if (!this.props.src) {
      return null;
    }
    const ytParams = 'autoplay=1&controls=1&hd=1&rel=0&cc_load_policy=1';
    let src = this.props.src;
    if (src.indexOf('?') !== -1) {
      src += '&' + ytParams;
    } else {
      src += '?' + ytParams;
    }
    const allowYoutube = this.props.blockDataStore.allowYoutube;
    if (!allowYoutube) {
      return (
        <BlockButton thumbnail={this.props.thumbnail} type="youtube" size={this.props.size} onClick={this.props.blockDataStore.onClickAllowYoutube} />
      );
    } else {
      return (
        <iframe title="Interview" width="640" height="360" src={src} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      );
    }
  }
}