import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import IconClose from '../svg/IconClose';

const GlossaryTooltip = inject('navigationStore', 'windowStore')(
  observer((props) => {
    const [alignToBottom, setAlignToBottom] = useState(false);
    const elementDiv = React.createRef();
    const isVisible = props.navigationStore.activeGlossaryTooltip === props.id;
    const handleClick = id => e => {
      props.navigationStore.setGlossaryTooltip(null);
    }

    useEffect(() => {
      if (isVisible && props.windowStore.isGreaterEqualThan('xl')) {
        const tooltipHeight = elementDiv.current.offsetHeight;
        const tooltipTop = elementDiv.current.offsetTop;
        const containerHeight = elementDiv.current.offsetParent.offsetHeight;

        if (tooltipTop + tooltipHeight > containerHeight) {
          setAlignToBottom(true);
        }
      } else {
        setAlignToBottom(false);
      }
    }, [elementDiv, isVisible, props.windowStore]);

    return (
      <span
        className={'mink-tooltip ' + (isVisible ? '' : 'd-none ') + (alignToBottom ? 'align-to-bottom ' : '')}
        id={ props.id }
        ref={elementDiv}
        >
        <span className='-close' onClick={ handleClick(props.id) }><IconClose className="bt-close" /></span>
        { props.children }
      </span>
    );
  })
);

export default GlossaryTooltip;
