import React from 'react';
import YoutubeIFrameInterview from '../../components/elements/YoutubeIFrame/YoutubeIFrameInterview';
import VideoButton from '../../components/elements/VideoButton/VideoButton';

const VideoPageTeaser = (props) => {

  const imageSrc = props.image?.[0];

  return (
    <div className="mink-video-teaser" id={props.slug}>
      { props.isSelected
        ?
        <div className="mink-video-teaser__video">
          <YoutubeIFrameInterview src={props.videoSrc} thumbnail={imageSrc} />
        </div>
        :
        <>
          <div className="mink-video-teaser__title">{props.title}</div>
          <VideoButton
            imgVideo={imageSrc}
            title={props.title}
            onClick={props.onClick}
          />
        </>
      }
    </div>
  );
}

export default VideoPageTeaser;
