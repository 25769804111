import React from 'react';
import SubMenu from '../SubMenu/SubMenu';
import useEventListener from '@use-it/event-listener';
import { renderText } from '../../../helpers/api';
import { Collapse } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import IconSearch from '../../elements/svg/IconSearch';
import IconTriangle from '../../elements/svg/IconTriangle';
import SearchInput from '../SearchInput/SearchInput';

const MobileMenu = inject('navigationStore', 'routerStore', 'windowStore')(
  observer((props) => {

    const elementNavMobile = React.createRef();
    const toggleNav = props.navigationStore.toggleNav;
    const toggleMobileShowHomeImages = props.navigationStore.toggleMobileShowHomeImages;

    const currentPath = props.routerStore.location.pathname;
    const isCurrentMenuItem = props.navigationStore.isCurrentMenuItem;
    const subMenuIsOpen = props.navigationStore.subMenuIsOpen;
    const subMenuId = props.navigationStore.subMenuId;

    const openSubMenu = (id) => {
      props.navigationStore.openSubMenu(id);
    }

    const items = [
      { title: '',
        url: '',
        menu_item_id: 'search',
        classes: ['search'],
      },
      ...props.items,
    ];

    const handleClick = item => e => {
      if (Array.isArray(item.children) && item.children.length > 0) {
        e.preventDefault();
        if (subMenuIsOpen && subMenuId === item.menu_item_id) {
          openSubMenu('');
        } else {
          openSubMenu(item.menu_item_id);
        }
      } else if (item.url) {
        props.navigationStore.handleClick(e, item);
      }
    };

    const handleDocumentClick = (e) => {
      // close menu on outside clicks
      if ( elementNavMobile?.current && !elementNavMobile.current.contains(e.target) ) {
        props.navigationStore.closeNav();
      }
    };

    useEventListener('click', handleDocumentClick, document, { capture: true });

    return (
      <nav className="mink-main-mobile-nav" ref={elementNavMobile}>
        <div className="mink-main-mobile-nav__header">
          {/* <div className="mink-header__brand">
            <Link to="/">Interviews</Link>
          </div> */}
          { currentPath === '/' &&
            <div
              className={'mink-main-mobile-nav__bt-home-images' + (props.navigationStore.mobileShowHomeImages ? ' is-active' : '')}
              onClick={toggleMobileShowHomeImages}
            ><IconTriangle className="icon-triangle" /></div>
          }
          <div className="mink-main-mobile-nav__toggler" onClick={toggleNav}>
            <div
              className={'navbar-toggler-icon' + (props.navigationStore.navIsActive ? ' is_active' : '')}
            >
              <span />
              <span />
              <span />
              <span />
            </div>
          </div>
        </div>
        <Collapse isOpen={props.navigationStore.navIsActive} className="mink-main-mobile-nav__container">
          <div className="mink-main-mobile-nav__menu container">
            <ul className="main-menu list-unstyled">
            {
              items.map((item, i) => {

                return (
                  <li key={'menu_item_' + item.menu_item_id} className={'nav_item ' + (item.classes.join(' '))}>
                    { item.menu_item_id === 'search' ? (
                      <div className="nav_link search-link">
                        <IconSearch className="search-icon" />
                        <SearchInput />
                      </div>
                    ) : (
                      <a className="nav_link" href={item.url} onClick={handleClick(item)} target={item.target}>
                        { renderText(item.title) }
                      </a>
                    )}
                    {
                      item.children && (
                        <Collapse isOpen={subMenuIsOpen && subMenuId === item.menu_item_id}>
                          <SubMenu items={item.children} depthLevel={1} handleClick={props.navigationStore.handleClick} isCurrentMenuItem={isCurrentMenuItem} currentPath={currentPath}/>
                        </Collapse>
                      )
                    }
                  </li>
                );
              })
            }
            </ul>
          </div>
        </Collapse>
      </nav>
    );
  })
);

export default MobileMenu;
