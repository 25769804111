import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { renderTitle, renderText } from '../../helpers/api';
import NoMatch from '../NoMatch/NoMatch';
import ScrollToTop from '../../components/nav/ScrollToTop/ScrollToTop';
// import ScrollToHash from '../../components/nav/ScrollToHash/ScrollToHash';
import LoadingAnimation from '../../components/nav/LoadingAnimation/LoadingAnimation';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import YoutubeIFrameInterview from '../../components/elements/YoutubeIFrame/YoutubeIFrameInterview';
import VideoButton from '../../components/elements/VideoButton/VideoButton';
import ReadProgressIndicator from '../../components/elements/ReadProgress/ReadProgressIndicator';
import ReadProgressWatcher from '../../components/elements/ReadProgress/ReadProgressWatcher';
import ScrollTopButton from '../../components/nav/ScrollTopButton/ScrollTopButton';
import IconDownload from '../../components/elements/svg/IconDownload';

const Single = inject(
  'pagesStore',
  'navigationStore',
  'windowStore'
)(
  observer((props) => {
    const [showVideo, setShowVideo] = useState(false);

    const store = props.store ? props.store : props.pagesStore;

    const getCurrentSlug = () => {
      if (props.match?.params) {
        if (props.apiParams?.postType) {
          return props.location.pathname;
        } else {
          const slug = props.match.params.id;
          return slug ? slug : 'homepage';
        }
      }
    };
    const slug = getCurrentSlug();

    const renderHead = () => {
      if (store.isFetched.get(slug) && store.data.posts[slug].title) {
        return renderTitle(store.data.posts[slug].title);
      } else {
        return null;
      }
    };

    const getImage = (post) => {
      const imageArray = post?.['thumbnail_large']; //  + (size.suffix ? '_' : '') + size.suffix];
      if (imageArray) {
        return (
          <img
            className="mink-title-image"
            src={imageArray[0]}
            width={imageArray[1]}
            height={imageArray[2]}
            alt={post.title}
          />
        );
      } else {
        return null;
      }
    };

    const clickVideoButton = () => {
      setShowVideo(!showVideo);
    };

    useEffect(() => {
      if (props.apiParams?.postType) {
        store.fetchPathIfNeeded(slug);
      } else {
        store.fetchIfNeeded(slug);
      }

      document.body.classList.add('page');
      if (store.isFetched.get(slug)) {
        const postType = store.data.posts[slug].post_type;
        if (postType) {
          document.body.classList.add('single-' + postType);
        }
      }

      return () => {
        document.body.classList.remove('page');
        if (store.isFetched.get(slug)) {
          const postType = store.data.posts[slug].post_type;
          if (postType) {
            document.body.classList.remove(postType, 'single-' + postType);
          }
        }
      };
    });

    const isFetched = store.isFetched.get(slug);
    const post = store.data.posts[slug];
    const postType = post?.post_type;
    const scrollToTop = !props.location.hash && !props.location.state?.scrollTo;

    if ((isFetched && false === post) || (store.isFetchedAll && !post)) {
      return <NoMatch />;
    }

    const imageSrc = post?.['thumbnail_large']?.[0];
    const dummyThumb = post?.['thumbnail_large']?.[0];

    const intro = post?.fields?.interview_intro;
    const vita = post?.fields?.interview_vita;
    const pdf = post?.fields?.interview_print?.url;
    const video = post?.fields?.interview_video;
    const videoSrc = post?.fields?.interview_video_src;

    return (
      <div>
        <Header />
        {isFetched ? (
          <main className="mink-main">
            <div className="container">
              <div className="mink-page">
                <div className="mink-content">
                  {scrollToTop && <ScrollToTop onHistoryPush={true} slug={slug} hasProgress={postType === 'post'} />}
                  {/* <ScrollToHash /> */}
                  {postType === 'post' &&
                    <>
                      <ReadProgressIndicator slug={slug} />
                      <ReadProgressWatcher slug={slug} />
                    </>
                  }
                  {renderHead()}
                  <div className="row">
                    <div className="col-12 col-xl-9 -post-content mink-interview">
                      <div className="mink-magic-top-cover"></div>

                      {props.windowStore.isGreaterEqualThan('xl') ? (
                        <>
                          {video && showVideo ? (
                            <div className="mink-interview__video">
                              <YoutubeIFrameInterview src={videoSrc} thumbnail={dummyThumb} />
                            </div>
                          ) : (
                            getImage(post)
                          )}
                        </>
                      ) : (
                        <>
                          {video ? (
                            <>
                              {showVideo ? (
                                <div className="mink-interview__video">
                                  <YoutubeIFrameInterview src={videoSrc} thumbnail={dummyThumb} />
                                </div>
                              ) : (
                                <VideoButton
                                  isPressed={showVideo}
                                  imgTitle={imageSrc}
                                  imgVideo={dummyThumb}
                                  title={post.title}
                                  showTitle={true}
                                  onClick={clickVideoButton}
                                />
                              )}
                            </>
                          ) : (
                            getImage(post)
                          )}
                        </>
                      )}

                      <h1>{post.title}</h1>
                      {intro && <div className="mink-interview__intro">{renderText(intro)}</div>}

                      {renderText(post.content)}
                    </div>
                    <div className="col-12 col-xl-3 mink-interview">
                      {video && props.windowStore.isGreaterEqualThan('xl') && (
                        <VideoButton
                          isPressed={showVideo}
                          imgTitle={imageSrc}
                          imgVideo={dummyThumb}
                          title={post.title}
                          showTitle={true}
                          onClick={clickVideoButton}
                        />
                      )}
                      {vita && <div className="mink-vita">{renderText(vita)}</div>}
                      {pdf && (
                        <div className="mink-pdf">
                          <a className="mink-pdf-link" href={pdf} target="_blank" rel="noopener noreferrer">
                            <IconDownload className="icon-download" />
                            <span className="mink-pdf-link-text">Interview als PDF</span>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        ) : (
          <LoadingAnimation />
        )}
        <ScrollTopButton />
        <Footer />
      </div>
    );
  })
);
export default Single;
