import React from 'react';
import { observer, inject } from 'mobx-react';

const GlossaryLink = inject('navigationStore')(
  observer((props) => {

    const handleClick = id => e => {
      e.preventDefault();
      props.navigationStore.setGlossaryTooltip(id);
    }
    return (
      <span className="glossary-link">
        <a onClick={ handleClick(props.id) } href="/glossary/">{ props.linkText }</a>
      </span>
    );
  })
);

export default GlossaryLink;
