import React, { Component } from 'react';
import FooterNav from '../../containers/FooterNav/FooterNav';
import { inject, observer } from 'mobx-react';
import Partners from './Partners';

@inject('sidebarsStore', 'navigationStore', 'scrollStore')
@observer
class Footer extends Component {
  sidebar = [];

  constructor(props) {
    super(props);
    this.sidebar = props.sidebarsStore;
  }

  componentDidMount() {
    this.props.sidebarsStore.fetchIfNeeded('sidebar-footer');
  }

  componentDidUpdate() {
    this.props.sidebarsStore.fetchIfNeeded('sidebar-footer');
  }

  render() {

    return (
      <footer className="mink-footer">
        <div className="container">
          <div className="mink-footer__content">
            <div className="row">
              <div className="col-12 d-flex">
                <h2 className="mink-footer__title">Kunstszene/Ost</h2>
              </div>
            </div>
            <FooterNav />
            <Partners />
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
