import { action, observable, makeObservable } from 'mobx';

export default class BlockDataStore {

  userSettings = {
    allowYoutube: false,
  };

  allowYoutube = false;

  constructor() {
    makeObservable(this, {
      allowYoutube: observable,
      setAllowYoutube: action,
    });

    if (localStorage) {
      const allowYoutube = localStorage.getItem('mink.allowYoutube');
      if(allowYoutube === 'true') {
        this.allowYoutube = true;
      }
    }
  }

  setAllowYoutube() {
    this.allowYoutube = true;
    if (localStorage) {
      localStorage.setItem('mink.allowYoutube', true);
    }
  }
  /**
   * Click Handler
   * @param {Event} e
   * @returns bool
   */
  onClickAllowYoutube = (e) => {
    this.setAllowYoutube();
    e.preventDefault();
    return false;
  }

}