import { action, autorun, reaction, makeObservable } from 'mobx';
import { /* isInCenterOfViewPort, */ WINDOW_EXISTS } from '../../helpers/viewport';
import { isMobile, isSafari } from '../../helpers/browser';
import animateScrollTo from 'animated-scroll-to';


export default class ScrollStore {

  components = [];
  /**
   * {
   *   name: string,
   *   elementRef: React.RefObject,
   *   path: string,
   * }
   */
  currentCmp = {};
  isDisabled = false;
  ignoreNextScrollEvent = false;
  resizeTimeout = false;
  delay = 250;
  firstAidRef = {};

  constructor (routerStore, windowStore, scrollManager) {
    makeObservable(this, {
      setScrollToMeStart: action,
      setScrollToMeEnd: action
    });

    this.routerStore = routerStore;
    this.windowStore = windowStore;
    this.scrollManager = scrollManager;
    if (WINDOW_EXISTS) {
      window.addEventListener('window-scroll', this.handleScrollChange, {passive: true});
      window.addEventListener('resize', this.handleResize);
      window.addEventListener('popstate', this.handlePopState);
    }
    // on location change and if has a hash
    autorun(
      () => {
        if (
          this.routerStore.location &&
          this.routerStore.location.hash &&
          this.routerStore.location.state &&
          this.routerStore.location.state.scrollToMe
        ) {
          this.setScrollToMeStart();
        }
      }
    );

    // react to window height or size change
    this.windowHeightReaction = reaction(
      () => ({
        windowHeight: this.windowStore.windowHeight,
        pageSize: this.windowStore.pageSize
      }),
      (data) => {
        // this.recalculateBodyHeight();
      },
      {delay: 1}
    );

  }

  handlePopState = () => {
    // const newPath = window.location.pathname;
    this.ignoreNextScrollEvent = true;
    let cmp = null;
    // for (let i = 0; i < this.components.length; i++) {
    //   if (newPath === this.components[i].path) {
    //     cmp = this.components[i];
    //   }
    // }
    if (cmp) {
      this.currentCmp = cmp;
      // this.animateScrollTo({desiredOffset: "/" !== cmp.path ? cmp.elementRef.current : 0});
      window.scrollTo({ top: cmp.path ? cmp.elementRef.current : 0, left: 0, behavior: 'instant' });
    }
  }

  handleResize = (e) => {
    // clear the timeout
    clearTimeout(this.resizeTimeout);
    // start timing for event "completion"
    this.resizeTimeout = setTimeout(() => {this.handleScrollChange(e)}, this.delay);
  }

  handleScrollChange = (e) => {
    if (this.ignoreNextScrollEvent) {
      this.ignoreNextScrollEvent = false;
      return;
    }

    /*if (e.type === 'window-scroll') {
      console.log(e.detail.scrollPosition);
    }*/
    if (!this.isDisabled) {
      let cmp = null;
      // for (let i = 0; i < this.components.length; i++) {
      //   if (isInCenterOfViewPort(this.components[i].elementRef.current, e)) {
      //     cmp = this.components[i];
      //   }
      // }
      // ensure to switch to startpage on scroll To Top
      // if ('window-scroll' === e.type && e.detail.scrollPosition < 150) {
      //   cmp = this.components.find(c => 'StartPage' === c.name)
      // }
      if (cmp && cmp.path !== this.routerStore.location.pathname) {
        this.routerStore.push({pathname: cmp.path, state: {noScroll: true}});
        this.currentCmp = cmp;
      }
    }
  }

  // subscripeToScrollManager = (config) => {
  //   if (-1 === this.components.indexOf(config)) {
  //     this.components.push(config);
  //   }
  // }

  // unsubscripeFromScrollManager = (config) => {
  //   const index = this.components.indexOf(config);
  //   if (-1 !== index) {
  //     this.components.splice(index, 1);
  //   }
  // }

  setScrollToMeStart() {
    this.scrollToMeHash = this.routerStore.location.hash;
  }

  setScrollToMeEnd() {
    this.scrollToMeHash = '';
  }

  /**
   * @see https://github.com/Stanko/animated-scroll-to
   * @param {} options
   */
  animateScrollTo(options) {
    const defaults = {
      cancelOnUserAction: false,
      speed: 500,
      minDuration: 250,
      maxDuration: 750,
      verticalOffset: this.windowStore.isGreaterEqualThan('xxl') ? -200 : -104,
    };
    if (typeof options === 'undefined') {
      options = { ...defaults};
    } else {
      options = { ...defaults, ...options }
    }

    if (isSafari() && !isMobile()) {
      //options.speed = 0;
      //options.minDuration = 0;
      //options.maxDuration = 0;
    }

    if (typeof options.desiredOffset === 'undefined') {
      // const location = this.routerStore.location;
      // for (let i = 0; i < this.components.length; i++) {
      //   // in case of / component scroll to top, cause of the header
      //   if (this.components[i]['path'] === this.routerStore.location.pathname) {
      //     if ( "/" === this.routerStore.location.pathname ) {
      //       options.desiredOffset = 0;
      //     } else {
      //       options.desiredOffset = this.components[i].elementRef.current;
      //     }
      //     if (
      //       "/" === this.routerStore.location.pathname ||
      //       (location.state && true === location.state.noScroll)
      //     ) {
      //       options.maxDuration = 0;
      //     }
      //     break;
      //   }
      // }
    }

    return new Promise ((resolve) => {
      if (typeof options.desiredOffset !== 'undefined') {
        this.isDisabled = true;
        document.body.classList.add('scroll-active');
        animateScrollTo(options.desiredOffset, options)
          .then((resolved) => {
            if (typeof options.complete !== 'undefined') {
              options.complete();
            }
            this.setScrollToMeEnd();
            this.isDisabled = false;
            document.body.classList.remove('scroll-active');
            resolve(options.desiredOffset);
          });
      }
      else {
        resolve(null);
      }
    });
  }
}
