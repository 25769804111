import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { renderTitle } from '../../helpers/api';
import NoMatch from '../NoMatch/NoMatch';
import ScrollToTop from '../../components/nav/ScrollToTop/ScrollToTop';
import ScrollToHash from '../../components/nav/ScrollToHash/ScrollToHash';
import LoadingAnimation from '../../components/nav/LoadingAnimation/LoadingAnimation';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import HomePageTeaser from './HomePageTeaser';

const HomePage = inject('pagesStore', 'windowStore', 'navigationStore')(
  observer((props) => {
    const slug = 'homepage';

    const renderHead = () => {
      if (
        props.pagesStore.isFetched.get(slug) &&
        props.pagesStore.data.posts[slug].location &&
        props.pagesStore.data.posts[slug].location.title
      ) {
        return renderTitle(props.pagesStore.data.posts[slug].location.title, false);
      } else {
        return null;
      }
    };

    const shuffleArray = (arr) => {
      if (Array.isArray(arr)) {
        const origArray = [...arr];
        const newArray = [];
        while (origArray.length > 0) {
          newArray.push(origArray.splice(Math.floor(Math.random() * origArray.length), 1)[0]);
        }
        return newArray;
      } else {
        return [];
      }
    }

    useEffect(() => {
      props.pagesStore.fetchIfNeeded(slug);
      document.body.classList.add('page', '-homepage');

      return () => document.body.classList.remove('page', '-homepage');
    }, [slug, props.pagesStore]);

    const isFetched = props.pagesStore.isFetched.get(slug);
    const post = props.pagesStore.data.posts[slug];
    const teasers = shuffleArray(Array.isArray(post?.fields) ? post.fields : []);
    const scrollToTop = !props.location.hash && (!props.location.state || !props.location.state.scrollTo);

    if (isFetched && false === post) {
      return <NoMatch location={props.location} />;
    }

    return (
      <div>
        <Header />
        {isFetched ? (
          <main className={'mink-main'}>
            <div className="container">
              <div className="mink-page">
                <div className="mink-content">
                  {scrollToTop && <ScrollToTop onHistoryPush={true} />}
                  <ScrollToHash />
                  {renderHead()}
                  <div className="row">
                    <div className="col-12">
                      <div className="mink-magic-top-cover"></div>
                      <div className={'mink-teaser__container row'}>
                        {
                          teasers.map((teaser, i) => {
                            return (
                              <HomePageTeaser
                                key={teaser.slug}
                                slug={teaser.slug}
                                title={teaser.title}
                                image={teaser.thumbnail_medium}
                                showImage={props.windowStore.isGreaterEqualThan('lg') || props.navigationStore.mobileShowHomeImages}
                              />
                            );
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        ) : (
          <LoadingAnimation />
        )}
        <Footer />
      </div>
    );
  })
);

export default HomePage;