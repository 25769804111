import React from 'react';
import ReadProgressIndicator from '../../components/elements/ReadProgress/ReadProgressIndicator';
import { Link } from 'react-router-dom';

const HomePageTeaser = (props) => {

  return (
    <Link className={'mink-teaser'} to={'/' + props.slug}>
      { props.image && props.showImage &&
        <>
          <img
            className="mink-teaser__image"
            src={props.image[0]}
            width={props.image[1]}
            height={props.image[2]}
            alt={props.title}
          />
          <ReadProgressIndicator slug={props.slug} />
        </>
      }
      <div className="mink-teaser__title">{props.title}</div>
    </Link>
  );
}

export default HomePageTeaser;
