import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { renderTitle, renderText } from '../../helpers/api';
import NoMatch from '../NoMatch/NoMatch';
import ScrollToTop from '../../components/nav/ScrollToTop/ScrollToTop';
import ScrollToHash from '../../components/nav/ScrollToHash/ScrollToHash';
import LoadingAnimation from '../../components/nav/LoadingAnimation/LoadingAnimation';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import ScrollTopButton from '../../components/nav/ScrollTopButton/ScrollTopButton';
import GlossaryNav from '../../components/elements/Glossary/GlossaryNav';

const GlossaryPage = inject(
  'pagesStore',
  'navigationStore',
  'windowStore'
)(
  observer((props) => {
    const store = props.store ? props.store : props.pagesStore;

    const getCurrentSlug = () => {
      if (props.match?.params) {
        if (props.apiParams?.postType) {
          return props.location.pathname;
        } else {
          const slug = props.match.params.id;
          return slug ? slug : 'glossar';
        }
      }
    };
    const slug = getCurrentSlug();

    const renderHead = () => {
      if (store.isFetched.get(slug) && store.data.posts[slug].title) {
        return renderTitle(store.data.posts[slug].title);
      } else {
        return null;
      }
    };

    useEffect(() => {
      if (props.apiParams?.postType) {
        store.fetchPathIfNeeded(slug);
      } else {
        store.fetchIfNeeded(slug);
      }

      document.body.classList.add('page');
      if (store.isFetched.get(slug)) {
        const postType = store.data.posts[slug].post_type;
        if (postType) {
          document.body.classList.add('single-' + postType);
        }
      }

      return () => {
        document.body.classList.remove('page');
        if (store.isFetched.get(slug)) {
          const postType = store.data.posts[slug].post_type;
          if (postType) {
            document.body.classList.remove(postType, 'single-' + postType);
          }
        }
      };
    });

    const isFetched = store.isFetched.get(slug);
    const post = store.data.posts[slug];
    const scrollToTop = !props.location.hash && !props.location.state?.scrollTo;

    if ((isFetched && false === post) || (store.isFetchedAll && !post)) {
      return <NoMatch />;
    }

    const groupByLetter = (entries) => {
      let arrayByLetter = [];
      let currentLetter = '#';
      let currentGroup = [];

      entries.forEach((element) => {
        if (element.letter !== currentLetter) {
          if (currentGroup.length > 0) {
            arrayByLetter.push(currentGroup.sort(compareByTitle));
            currentGroup = [];
          }
          currentLetter = element.letter;
        }

        currentGroup.push(element);
      });
      if (currentGroup.length > 0) {
        arrayByLetter.push(currentGroup.sort(compareByTitle));
      }

      // remove first entry (#) and add/move to the end
      arrayByLetter.push(arrayByLetter.shift());
      return arrayByLetter;
    };

    const compareByTitle = (entryA, entryB) => {
      let titleA = getSortTitle(entryA);
      let titleB = getSortTitle(entryB);

      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
      return 0;
    }

    const getSortTitle = (entry) => {
      const title = entry.title;
      if (!title || title.length === 0) {
        return '';
      }
      if (title.startsWith('„')) {
        return title.substring(1).toLowerCase();
      }

      return title.toLowerCase();
    }

    const glossarByLetter = groupByLetter(post?.glossary || []);

    return (
      <>
        <Header />
        {isFetched ? (
          <main className="mink-main">
            <div className="container">
              <div className="mink-page">
                <div className="mink-content mink-glossary">
                  {scrollToTop && <ScrollToTop onHistoryPush={true} />}
                  <ScrollToHash />
                  {renderHead()}
                  <div className="row">
                    <div className="col-12 col-xl-9 -post-content mink-glossary">
                      <div className="mink-magic-top-cover"></div>
                      <h1 className="mink-glossary__headline">{post.title}</h1>
                      {/* {renderText(post.content)} */}

                      {glossarByLetter.map((letterEntries, iLetter) => {
                        const letter = letterEntries[0].letter;

                        return (
                          <section key={letter}>
                            {iLetter > 0 && <div className="row mink-glossary__divider"></div>}
                            <h2
                              id={'#' === letter ? 'etc' : letter.toLowerCase()}
                              className="mink-glossary-entry__letter"
                            >
                              {'#' === letter ? '' : letter.toUpperCase()}
                            </h2>

                            {letterEntries.map((entry, i) => {
                              let linkTitle = '';
                              if (entry?.link) {
                                linkTitle = entry?.link.replace('https://', '');
                              }

                              return (
                                <div key={i} className={'mink-glossary-entry'}>
                                  <h3 id={entry.slug} className="mink-glossary-entry__title">
                                    {entry.title}
                                    {entry?.interview && (
                                      <a
                                        className="mink-glossary-entry__interview"
                                        href={entry.interview[0] ? '/' + entry.interview[0]?.post_name : '/'}
                                      >
                                        zum Kunstszene/Ost-Interview mit {entry.interview[0]?.post_title}
                                      </a>
                                    )}
                                  </h3>
                                  {renderText(entry.content)}
                                  {entry?.link && (
                                    <p className="mink-glossary-entry__link">
                                      <a href={entry.link} rel="nofollow noopener noreferrer" target="_blank">
                                        {linkTitle}
                                      </a>
                                    </p>
                                  )}
                                </div>
                              );
                            })}
                          </section>
                        );
                      })}
                    </div>
                    <div className="col-12 col-xl-3 position-relative order-first order-xl-last">
                      <GlossaryNav />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        ) : (
          <LoadingAnimation />
        )}
        <ScrollTopButton />
        <Footer />
      </>
    );
  })
);
export default GlossaryPage;
