import * as React from "react";

function IconClose(props) {
  return (
    <svg
      {...props}
      fill="currentColor"
      width="100%"
      height="100%"
      viewBox="0 0 24 24">
      <path d="M11.796,13.1L5.917,18.978L4.613,17.674L10.491,11.796L4.613,5.917L5.917,4.613L11.796,10.491L17.674,4.613L18.978,5.917L13.1,11.796L18.978,17.674L17.674,18.978L11.796,13.1Z" />
    </svg>
  );
}

export default IconClose;