import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { renderTitle } from '../../helpers/api';
import NoMatch from '../NoMatch/NoMatch';
import ScrollToTop from '../../components/nav/ScrollToTop/ScrollToTop';
import ScrollToHash from '../../components/nav/ScrollToHash/ScrollToHash';
import LoadingAnimation from '../../components/nav/LoadingAnimation/LoadingAnimation';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import VideoPageTeaser from './VideoPageTeaser';

const VideoPage = inject('pagesStore', 'windowStore')(
  observer((props) => {
    const slug = 'homepage';
    const [selectedVideo, setSelectedVideo] = useState(null);

    const renderHead = () => {
      if (
        props.pagesStore.isFetched.get(slug) &&
        props.pagesStore.data.posts[slug].location &&
        props.pagesStore.data.posts[slug].location.title
      ) {
        return renderTitle(props.pagesStore.data.posts[slug].location.title, false);
      } else {
        return null;
      }
    };

    const handleTeaserClick = slug => e => {
      setSelectedVideo(slug);
    }

    useEffect(() => {
      if (props.location?.hash) {
        const hash = props.location.hash.replace(new RegExp('#', 'g'), '');
        setSelectedVideo(hash);
      }
    } , [props.location.hash]);

    useEffect(() => {
      props.pagesStore.fetchIfNeeded(slug);
      document.body.classList.add('page', '-videopage');

      return () => document.body.classList.remove('page', '-videopage');
    }, [slug, props.pagesStore]);

    const isFetched = props.pagesStore.isFetched.get(slug);
    const post = props.pagesStore.data.posts[slug];
    const teasers = Array.isArray(post?.fields) ? post.fields : [];
    const scrollToTop = !props.location.hash && (!props.location.state || !props.location.state.scrollTo);

    if (isFetched && false === post) {
      return <NoMatch location={props.location} />;
    }

    return (
      <div>
        <Header />
        {isFetched ? (
          <main className={'mink-main'}>
            <div className="container">
              <div className="mink-page">
                <div className="mink-content">
                  {scrollToTop && <ScrollToTop onHistoryPush={true} />}
                  <ScrollToHash />
                  {renderHead()}
                  <div className="row">
                    <div className="col-12">
                      <div className={'mink-video-teaser__container'}>
                        {
                          teasers.map((teaser) => {
                            if (teaser.video_src) {
                              return (
                                <VideoPageTeaser
                                  key={teaser.slug}
                                  slug={teaser.slug}
                                  title={teaser.title}
                                  image={teaser.thumbnail_medium}
                                  videoSrc={teaser.video_src}
                                  isSelected={selectedVideo === teaser.slug}
                                  onClick={handleTeaserClick(teaser.slug)}
                                />
                              );
                            } else {
                              return null;
                            }
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        ) : (
          <LoadingAnimation />
        )}
        <Footer />
      </div>
    );
  })
);

export default VideoPage;