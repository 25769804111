import React from 'react';
import { observer, inject } from 'mobx-react';

const ReadProgressIndicator = inject('readProgressStore')(
  observer((props) => {

    const progress = props.readProgressStore.readProgress[props.slug];

    return (
      <div className="mink-interview__read-progress">
        <div className="mink-interview__read-progress-bar" style={{width: progress ? progress + '%' : 0}}/>
      </div>
    );
  })
);
export default ReadProgressIndicator
