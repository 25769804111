import * as React from "react";

function IconSearch(props) {
  return (
    <svg
      {...props}
      fill="currentColor"
      width="100%"
      height="100%"
      viewBox="0 0 24 24">
      <path d="M24.008,21.886L19.028,16.906C20.414,15.086 21.158,12.856 21.142,10.564C21.181,4.763 16.503,0.031 10.703,0L10.571,0C4.771,-0.038 0.039,4.631 0,10.432L0,10.564C-0.038,16.364 4.639,21.096 10.44,21.135L10.571,21.135C12.864,21.15 15.094,20.407 16.914,19.02L21.894,24L24.008,21.886ZM3.021,10.564C2.982,6.436 6.296,3.052 10.424,3.013L10.571,3.013C14.699,2.974 18.076,6.289 18.122,10.416L18.122,10.564C18.161,14.691 14.846,18.068 10.718,18.114L10.571,18.114C6.444,18.153 3.067,14.838 3.021,10.711L3.021,10.564Z"/>
    </svg>
  );
}

export default IconSearch;