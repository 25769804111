import { action, observable, makeObservable } from 'mobx';

export default class NavigationStore {

  /**
   * holds the routerStore
   */
  routerStore = {}

  /**
   * // To stop listening, call the function returned from listen().
   * unlisten();
   */
  unlisten = null;
  primaryNavigationItems = [];
  /**
   * holds current pathname
   */
  pathname = '';
  navIsActive = false;
  subMenuIsOpen = false;
  subMenuId = '';
  mobileShowHomeImages = true;
  colIsActive = false;
  activeGlossaryTooltip = null;

  constructor(routerStore) {
    makeObservable(this, {
      navIsActive: observable,
      subMenuIsOpen: observable,
      subMenuId: observable,
      mobileShowHomeImages: observable,
      colIsActive: observable,
      activeGlossaryTooltip: observable,
      openSubMenu: action,
      toggleNav: action,
      closeNav: action,
      setGlossaryTooltip: action,
      toggleMobileShowHomeImages: action
    });

    if ( typeof window !== 'undefined' && typeof window.MINK !== 'undefined' && window.MINK.primary_navigation ) {
      this.primaryNavigationItems = window.MINK.primary_navigation;
      this.secondaryNavigationItems = window.MINK.secondary_navigation;
      this.conceptsNavigationItems = window.MINK.concepts_navigation;
      this.footerNavigationItems = window.MINK.footer_navigation;
      this.footerNavigationInterviewItems = window.MINK.footer_navigation_interview;
      this.postTypesRoutes = window.MINK.routes;
      this.activeLanguges = window.MINK.al;

      // this.footerNavigationVideoItems = window.MINK.footer_navigation_video;
      // replace link path
      const videoMenuItems = (window.MINK.footer_navigation_video || []).map((item) => {
        if (item.url_components) {
          const path = item.url_components.path;
          const host = item.url_components.host;
          const scheme = item.url_components.scheme;
          const id = path.replace(new RegExp('/', 'g'), '');
          const newPath = '/videos/#' + id;
          item.url_components.path = newPath;
          item.url = scheme + '://' + host + newPath;
          return item;
        } else {
          return null;
        }
      });
      this.footerNavigationVideoItems = videoMenuItems;
    }
    this.routerStore = routerStore;
    this.pathname = routerStore.location.pathname;
    // listen to history
    this.unlisten = routerStore.history.listen((location, action) => {
      if (this.pathname !== location.pathname) {
        this.pathname = location.pathname;
        // this.closeNav();
      }
    });
  }

  handleClick = (e, item) => {
    if ('#open-map' === item.url ){
      this.toggleMap(true);
      e.preventDefault();
      return false;
    }

    const urlComponents = item.url_components;

    // use routerStore only if link is internal
    const homeUrl = this.postTypesRoutes._meta.home_url;
    const isExtern = undefined !== urlComponents.scheme &&
      (
        !/http/i.test(urlComponents.scheme) ||
        (urlComponents.host && !new RegExp(urlComponents.host, 'i').test(homeUrl))
      );

    if (!isExtern) {
      let path;
      if (!typeof urlComponents === 'object' || !urlComponents.hasOwnProperty('path')) {
        path = item.url;
      } else {
        path = urlComponents.path;
        if (urlComponents.hasOwnProperty('query')) {
          path = path + '?' + urlComponents.query
        }
        if (urlComponents.hasOwnProperty('fragment')) {
          path = path + '#' + urlComponents.fragment
        }
      }

      e.preventDefault();
      this.changeRoute(path);
      return false;
    } else {
      console.error('Can\'t push external route to router: ' + item.url_components.scheme + '://' + item.url_components.host + item.url_components.path);
    }
  }

  changeRoute = (path) => {
    this.routerStore.push(path);
    this.closeNav();
    this.setGlossaryTooltip(null);
  }

  isCurrentMenuItem = (urlComponents) => {
    const cp = this.routerStore.location.pathname;
    if (typeof urlComponents === 'object' && urlComponents.hasOwnProperty('path')) {
      return (cp === urlComponents.path)
    }
    return  false;
  };

  openSubMenu = (id) => {
    if (id && !(this.subMenuIsOpen && this.subMenuId === id)) {
      this.subMenuId = id;
      this.subMenuIsOpen = true;
    } else {
      this.subMenuIsOpen = false;
    }
  };

  toggleNav = () => {
    this.subMenuIsOpen = false;
    this.navIsActive = !this.navIsActive;

    if (this.navIsActive) {
      document.body.classList.add('show-mobile-nav');
    } else {
      document.body.classList.remove('show-mobile-nav');
    }
  };


  /**
   * on location change / navigate close the menu
   */
  closeNav = () => {
    this.subMenuIsOpen = false;
    this.navIsActive = false;
    document.body.classList.remove('show-mobile-nav');
  };

  setGlossaryTooltip = (id) => {
    if (id && id === this.activeGlossaryTooltip) {
      this.activeGlossaryTooltip = null;
    } else {
      this.activeGlossaryTooltip = id;
    }
  }

  toggleMobileShowHomeImages = () => {
    this.mobileShowHomeImages = !this.mobileShowHomeImages;
  }

}
