import React from 'react';
import { observer, inject } from 'mobx-react';

const GlossaryNavItem = inject('scrollStore', 'windowStore')(
  observer((props) => {

    const onClick = () => {
      if (typeof document !== 'undefined' && props.letter) {
        let scrollTarget = document.getElementById(props.letter);
        if (!scrollTarget && props.letter === 'x') {
          scrollTarget = document.getElementById('y');
        }
        if (scrollTarget?.offsetTop) {
          props.scrollStore.animateScrollTo({ desiredOffset: Math.max(0, scrollTarget.offsetTop - 32) });
        }
      }
    };

    return (
      <div className={ 'mink-glossary__nav-letter' + (props.letter === 'x' ? ' letter-xy' : '') } onClick={ onClick }>
        { props.letter !== 'x'
          ?
            props.letter.toUpperCase()
          :
            <>
              <span className="x">X</span>
              <span className="y">Y</span>
            </>
        }
      </div>
    );
  })
);

export default GlossaryNavItem;
