import * as React from "react";

function IconDownload(props) {
  return (
    <svg
      {...props}
      fill="currentColor"
      width="100%"
      height="100%"
      viewBox="0 0 28 24">
      <path d="M13.157,0L14.62,0L14.62,14.486L19.42,9.581L20.462,10.642L13.931,17.297L7.305,10.642L8.347,9.59L13.147,14.41C13.157,14.4 13.157,0 13.157,0ZM26.304,10.805L27.767,10.805L27.767,24L0,24L0,10.814L1.463,10.814L1.463,22.508L26.314,22.508L26.314,10.805L26.304,10.805Z"/>
    </svg>
  );
}

export default IconDownload;