import React from 'react';
import { Route, matchPath } from 'react-router-dom';
import Single from '../containers/Single/Single';
import NoMatch from '../containers/NoMatch/NoMatch';
import { default as stores } from '../stores/index';
import HomePage from '../containers/HomePage/HomePage';
import VideoPage from '../containers/VideoPage/VideoPage';
import GlossaryPage from '../containers/GlossayPage/GlossayPage';
import Search from '../containers/Search/Search';

const preparePostTypesRoutes = (customRoutes) => {
  const postTypesRoutes = stores.navigationStore.postTypesRoutes;
  const routes = [];

  for (const p_key in postTypesRoutes) {
    if ('_meta' !== p_key && postTypesRoutes.hasOwnProperty(p_key)) {
      const postType = postTypesRoutes[p_key];
      // first define route for posts of this post_type if it has rewrite slug
      // and not defined in customRoutes like members
      if (postType._rewrite) {
        const shema = '/' + postType._rewrite + '/:id';
        if (!customRoutes.find((route) => route.path === shema)) {
          routes.push({
            path: shema,
            exact: true,
            component: Single,
            apiParams: {
              postType: p_key,
            },
          });
        }
      }
      // define this post_types archive route
      // if (postType._link) {
      //   const shema = postType._link.replace(postTypesRoutes._meta.home_url, '');
      //   if (shema && !customRoutes.find((route) => route.path === shema)) {
      //     routes.push({
      //       path: shema,
      //       exact: true,
      //       component: Archive,
      //       apiParams: {
      //         postType: p_key,
      //         type: 'post_type_archive',
      //       },
      //     });
      //   }
      // }
      // define routes for taxonomy archive sites
      // we don't want archives here
      // also commented out in
      /*
      for (const t_key in postType) {
        if ('_' !== t_key.charAt(0) &&  postType.hasOwnProperty(t_key)) {
          const taxonomy = postType[t_key];
          for (const term_slug in taxonomy) {
            if (taxonomy.hasOwnProperty(term_slug) && '_' !== term_slug.charAt(0)) {
              const term = taxonomy[term_slug];
              if (!customRoutes.find((route) => route.path === term.link)) {
                routes.push(
                  {
                    path: term.link,
                    exact: true,
                    component: Archive,
                    apiParams: {
                      restBase: taxonomy._rest_base,
                      postType: p_key,
                      taxonomy: t_key,
                      termId: term.id,
                      type: 'taxonomy'
                    }
                  }
                );
              }
            }
          }
        }
      }*/
    }
  }
  return routes;
};

// https://www.npmjs.com/package/path-to-regexp
// https://forbeslindesay.github.io/express-route-tester/
const customRoutes = [
  {
    path: '/',
    exact: true,
    component: HomePage,
  },
  {
    path: '/videos',
    exact: true,
    component: VideoPage,
  },
  {
    path: '/glossar',
    exact: true,
    component: GlossaryPage,
  },
  {
    path: '/suche',
    exact: true,
    component: Search,
  },
  {
    path: '/:path([\\w-/]*)?/:id',
    // /:id([\\w-]*)
    exact: true,
    component: Single,
  },
  {
    path: '*',
    component: NoMatch,
  },
];

const postTypesRoutes = preparePostTypesRoutes(customRoutes);
const ROUTES = [...postTypesRoutes, ...customRoutes];

// wrap <Route> and use this everywhere instead, then when
// sub routes are added to any route it'll work
const RouteWithSubRoutes = (route) => {
  if (route.children) {
    return (
      <Route
        path={route.path}
        exact={route.exact}
        children={({ ...props }) => {
          // pass the sub-routes down to keep nesting
          return (
            <route.children
              {...props}
              key={route.location.pathname}
              routes={route.routes}
              path={route.path}
              siblingPaths={route.siblingPaths}
            />
          );
        }}
      />
    );
  } else {
    return (
      <Route
        location={route.location}
        path={route.path}
        exact={route.exact}
        render={(props) => {
          // pass the sub-routes down to keep nesting
          return (
            <route.component
              {...props}
              key={route.location.pathname}
              routes={route.routes}
              path={route.path}
              siblingPaths={route.siblingPaths}
              store={route.store}
              apiParams={route.apiParams}
            />
          );
        }}
      />
    );
  }
};

const getCurrentRoute = () => {
  const store = stores.routerStore;
  const routes = ROUTES;
  let match = null;
  for (let i = 0; i < routes.length; i++) {
    match = matchPath(store.location.pathname, routes[i]);
    if (match) {
      return routes[i];
    }
  }
  return routes[routes.length - 1];
};

/**
 *
 * @param {string} location current location
 */
const getCurrentSlug = (location) => {
  const route = getCurrentRoute();
  const match = matchPath(location.pathname, route);
  if (match && match.params) {
    if (route.apiParams && route.apiParams.postType) {
      return location.pathname;
    } else {
      const newSlug = match.params.id ? match.params.id : 'homepage';
      return newSlug;
    }
  }
};

/**
 *
 * @param {integer} id term id
 * @param {string} taxonomy slug
 */
const getRouteByTermId = (id, taxonomy = null) => {
  const routes = ROUTES;
  return id !== undefined && routes.find((route) => route.apiParams && route.apiParams.termId === id);
};

/**
 *
 * @param {string} path
 *
 * @returns {object|boolean} route object or false
 */
const getRouteByPath = (path) => {
  const routes = ROUTES;
  return path !== undefined && routes.find((route) => matchPath(path, route));
};

export { ROUTES, RouteWithSubRoutes, getCurrentRoute, getRouteByTermId, getRouteByPath, getCurrentSlug };
