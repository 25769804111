import React, { Component } from 'react';


export default class BlockButton extends Component {

  render () {
    const isYoutube = 'youtube' === this.props.type;
    const classes = this.props.size === 'sm' ? ' btn-sm' : '';

    const style = this.props.thumbnail ? {
      backgroundImage: `url(${this.props.thumbnail})`,
    } : {};
    return(
      <div className={'iframe-block' + (isYoutube ? ' youtube' : '')} style={style}>
        <button className={'btn' + classes} onClick={this.props.onClick}>Video von YouTube laden<div className="-mouse"></div></button>
        <div className={'iframe-block__caption' + classes}>
          Mit dem Laden des Videos akzeptieren Sie die Datenschutzerklärung von YouTube.<br/>
          <a href="https://policies.google.com/privacy" target="_blank" rel="nofollow noopener noreferrer">Mehr erfahren</a>
        </div>
      </div>
    );
  }
}